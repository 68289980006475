<script>
  import { onMount } from 'svelte';
  import { isAuthenticated, user } from '$lib/services/store.js';
  import { auth0 } from '$lib/services/auth0.js';
  import { getUser } from '$lib/services/api';
  import { formatDate } from '$lib/common/helpers/date';

  // remove after VITE_ENABLE_AUTH0 ff cleanup
  const isAuth0Enabled = import.meta.env.VITE_ENABLE_AUTH0 === 'true';

  onMount(async () => {
    if (!isAuth0Enabled) {
      return;
    }

    if (window.location.search.includes('code=')) {
      await auth0.handleRedirectCallback();
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    const authStatus = await auth0.isAuthenticated();

    if (authStatus) {
      const token = await auth0.getTokenSilently();
      const authUserData = await auth0.getUser();
      const { data: apiData } = await getUser(authUserData?.sub, token);
      const userData = apiData || {
        email: authUserData?.email,
        externalId: authUserData?.sub,
        dateJoined: formatDate(authUserData?.updated_at),
      };

      isAuthenticated.set(authStatus);
      user.set(userData);

      sessionStorage.setItem('token', token);
      sessionStorage.setItem('user', JSON.stringify(userData));
    } else {
      const token = sessionStorage.getItem('token');
      const userData = sessionStorage.getItem('user');

      isAuthenticated.set(Boolean(token));
      user.set(JSON.parse(userData));
    }
  });
</script>

<slot />
